import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import Subtitle from '../../components/common/subtitle'
import PageContainer from '../../components/common/page-container'

export default (props) => (
    <Layout title="Publications">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>Publications</Title>
                <Subtitle>Our books, whitepapers and other publications</Subtitle>
            </TitleContainer>
        </BackgroundContainer>

        <PageContainer>
            <p>Our Technical Director, Michael Peacock, has been involved in a number of technical publications. He is the author of 8 published books, a magazine tutorial and a video series.</p>
            <ul>
                <li><a href="https://amzn.to/2XRiBRq" target="_blank" rel="nofollow noopener">Laravel 5 Essentials (Technical Reviewer)</a></li>
                <li><a href="https://amzn.to/2Rbhgm9" target="_blank" rel="nofollow noopener">Jenkins Essentials (Technical Reviewer)</a></li>
                <li><a href="https://amzn.to/2RdOpxK" target="_blank" rel="nofollow noopener">Creating Development Environments with Vagrant, Second Edition (Author)</a></li>
                <li><a href="https://www.packtpub.com/virtualization-and-cloud/building-real-time-communication-applications-using-twilio-video" target="_blank" rel="nofollow noopener">Building Real-time Communication Applications using Twilio [Video Series] (Author)</a></li>
                <li><a href="https://amzn.to/2X8oQ6s" target="_blank" rel="nofollow noopener">Jenkins Continuous Integration Cookbook, Second Edition (Technical Reviewer)</a></li>
                <li><a href="https://amzn.to/2F1PLGT" target="_blank" rel="nofollow noopener">Advanced API Security: Securing APIs with OAuth 2.0, OpenID Connect, JWS and JWE (Technical Reviewer)&nbsp;</a></li>
                <li><a href="https://amzn.to/2KM6SAb" target="_blank" rel="nofollow noopener">Creating Development Environments with Vagrant (Author)</a></li>
                <li>Enable e-commerce with the Symfony event dispatcher component (Author) – Web &amp; PHP Magazine, Issue 13, April 2013</li>
                <li><a href="https://amzn.to/2F7NEBl" target="_blank" rel="nofollow noopener">Jenkins Continuous Integration Cookbook (Technical Reviewer)</a></li>
                <li><a href="https://amzn.to/2F7Ix4d" target="_blank" rel="nofollow noopener">Drupal 7 Social Networking (Author)</a></li>
                <li><a href="https://amzn.to/2KN521V" target="_blank" rel="nofollow noopener">PHP 5 Social Networking (Author)</a></li>
                <li><a href="https://amzn.to/2MIVLKT" target="_blank" rel="nofollow noopener">Drupal 6 Social Networking (Author)</a></li>
                <li><a href="https://amzn.to/2WGLPX0" target="_blank" rel="nofollow noopener">Drupal for Education and E-Learning (Technical Reviewer)</a></li>
                <li>Mobile Web Development (Technical Reviewer)</li>
                <li><a href="https://amzn.to/2ReDeo9" target="_blank" rel="nofollow noopener">Building websites with TYPO3 (Author)</a></li></ul>
        </PageContainer>
    </Layout >
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "publications.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
